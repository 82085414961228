import React from "react"
import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBJumbotron,
  MDBAnimation,
  MDBBtn,
  MDBCardText,
  MDBCardBody,
} from "mdbreact"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectTemplate = ({ data }) => (
  <Layout>
    <SEO title={data.prismicProject.data.title.text} />

    <MDBJumbotron fluid id="project" className="d-none d-md-block">
      <MDBContainer className="py-3 py-md-5 mt-5 mt-md-4">
        <MDBRow>
          <MDBCol size="12" lg="8" className="text-center text-md-left">
            <MDBAnimation type="fadeInUp" reveal>
              <span className="hero-text-block">
                <h1 className="text-secondary">
                  <strong>{data.prismicProject.data.title.text} </strong>
                </h1>
              </span>
            </MDBAnimation>
          </MDBCol>
          <MDBCol
            size="12"
            className="text-center text-md-left mx-auto mx-md-0"
          >
            <MDBAnimation type="fadeInUp" delay="0.3s" reveal>
              <h5 className="h5-responsive">
                {data.prismicProject.data.subtitle.text}
              </h5>
            </MDBAnimation>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBJumbotron>
    <MDBJumbotron fluid id="project-mobile" className="d-block d-md-none">
      <MDBContainer className="pt-4 pb-1 mt-5">
        <MDBRow>
          <MDBCol size="12" md="8" className="text-center text-md-left">
            <MDBAnimation type="fadeInUp" reveal>
              <span className="hero-text-block">
                <h1 className="h1-responsive text-secondary">
                  <strong>{data.prismicProject.data.title.text} </strong>
                </h1>
              </span>
            </MDBAnimation>
          </MDBCol>
          <MDBCol size="8" className="text-center text-md-left mx-auto mx-md-0">
            <MDBAnimation type="fadeInUp" delay="0.3s" reveal>
              <p>{data.prismicProject.data.subtitle.text}</p>
            </MDBAnimation>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBJumbotron>

    <MDBContainer>
      <MDBAnimation type="fadeIn" delay="0.3s" reveal>
        <Img
          className="d-block w-100"
          fluid={
            data.prismicProject.data.hero_img.localFile.childImageSharp.fluid
          }
          alt={data.prismicProject.data.hero_img.alt}
        />
      </MDBAnimation>
    </MDBContainer>
    <MDBContainer>
      <MDBRow className="py-5">
        <MDBCol size="12" md="7" lg="8">
          <MDBAnimation type="fadeInUp" delay="0.5s" reveal>
            <MDBRow>
              <MDBCol size="12" className="my-3">
                <h2 className="font-weight-bold h2-responsive text-center text-md-left">
                  <strong>
                    {data.prismicProject.data.overview_title.text}
                  </strong>
                </h2>
              </MDBCol>
              <MDBCol
                size="12"
                md="12"
                lg="6"
                className=" text-center text-md-left"
              >
                <p>{data.prismicProject.data.overview_text_1.text}</p>
              </MDBCol>
              <MDBCol
                size="12"
                md="12"
                lg="6"
                className=" text-center text-md-left"
              >
                <p>{data.prismicProject.data.overview_text_2.text}</p>
              </MDBCol>
            </MDBRow>
          </MDBAnimation>
        </MDBCol>
        <MDBCol size="12" md="5" lg="4">
          <MDBAnimation type="fadeInUp" delay="0.6s" reveal>
            <MDBCard>
              <MDBCardBody className=" text-center text-md-left">
                <h5>
                  <strong>Tools</strong>
                </h5>
                <MDBCardText>
                  {data.prismicProject.data.technology_used.text}
                </MDBCardText>
                <h5>
                  <strong>Role</strong>
                </h5>
                <MDBCardText>
                  {data.prismicProject.data.project_role.text}

                  {/* <MDBBadge color="default" className="mr-2 p-2">
                    Web Development
                  </MDBBadge>
                  <MDBBadge color="red" className="mr-2 p-2">
                    Graphic Design
                  </MDBBadge>
                  <MDBBadge color="blue" className="mr-2 p-2">
                    UI/UX
                  </MDBBadge> */}
                </MDBCardText>
                <OutboundLink
                  href={data.prismicProject.data.website_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBBtn
                    size="sm"
                    tag="span"
                    color="primary"
                    //className="btn-custom"
                  >
                    <MDBIcon icon="desktop" className="mr-2" />
                    View Website
                  </MDBBtn>
                </OutboundLink>
                {/* <MDBBtn
                  size="sm"
                  href={data.prismicProject.data.website_code_link.url}
                  target="_blank"
                  color="elegant"
                  //className="btn-custom"
                >
                  View Code <MDBIcon fab icon="github" className="ml-2" />
                </MDBBtn> */}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <MDBContainer>
      <MDBRow className="my-5  py-5 text-center text-md-left">
        <MDBCol className="order-md-last mb-5" size="12" md="5">
          <MDBAnimation type="fadeInUp" reveal>
            <Img
              fluid={
                data.prismicProject.data.section_1_img.localFile.childImageSharp
                  .fluid
              }
              alt={data.prismicProject.data.section_1_img.alt}
            />
          </MDBAnimation>
        </MDBCol>
        <MDBCol middle size="12" md="7">
          <MDBAnimation type="fadeInUp" reveal delay="0.3s">
            <h2 className="h2-responsive font-weight-bold">
              {data.prismicProject.data.section_1_title.text}
            </h2>
            <p>{data.prismicProject.data.section_1_content.text}</p>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <MDBContainer>
      <MDBRow className="my-5 py-5 text-center text-md-left">
        <MDBCol className="mb-5" size="12" md="5">
          <MDBAnimation type="fadeInUp" reveal>
            <Img
              fluid={
                data.prismicProject.data.section_2_img.localFile.childImageSharp
                  .fluid
              }
              alt={data.prismicProject.data.section_2_img.alt}
            />
          </MDBAnimation>
        </MDBCol>
        <MDBCol middle size="12" md="7">
          <MDBAnimation type="fadeInUp" reveal delay="0.3s">
            <h2 className="h2-responsive font-weight-bold">
              {data.prismicProject.data.section_2_title.text}
            </h2>
            <p>{data.prismicProject.data.section_2_content.text}</p>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    <section className="mt-md-5 other-projects">
      <MDBContainer>
        <MDBRow className="py-5">
          <MDBCol size="12" md="6">
            <MDBRow>
              <MDBCol size="12" md="6" className="text-center text-md-left">
                <Link to="/projects">
                  <MDBBtn className="white-text" color="elegant">
                    <MDBIcon icon="folder-open" className="mr-3" />
                    Return to Projects
                  </MDBBtn>
                </Link>
              </MDBCol>
              <MDBCol size="12" md="6" className="text-center text-md-left">
                <Link to="/contact">
                  <MDBBtn to="/contact" className="white-text" color="primary">
                    <MDBIcon icon="envelope" className="mr-3" />
                    Get In Touch
                  </MDBBtn>
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  </Layout>
)

export const query = graphql`
  query ProjectTemplate($id: String!) {
    prismicProject(id: { eq: $id }) {
      id
      uid
      data {
        title {
          text
        }
        subtitle {
          text
        }
        overview_text_1 {
          text
        }
        overview_text_2 {
          text
        }
        overview_title {
          text
        }
        hero_img {
          url
          alt
          localFile {
            id
            childImageSharp {
              fluid(
                maxHeight: 555
                maxWidth: 1110
                cropFocus: CENTER
                quality: 90
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        technology_used {
          text
        }
        project_role {
          text
        }
        website_link {
          url
        }
        website_code_link {
          url
        }
        section_1_title {
          text
        }
        section_1_content {
          text
        }
        section_1_img {
          alt
          localFile {
            childImageSharp {
              fluid(cropFocus: CENTER, quality: 90) {
                src
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_2_title {
          text
        }
        section_2_content {
          text
        }
        section_2_img {
          alt
          localFile {
            childImageSharp {
              fluid(cropFocus: CENTER, quality: 90) {
                src
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
export default ProjectTemplate
